import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from 'components/button/Button';
import { Segment } from 'components/layout/Segment';

import s from './MoreButton.scss';

interface IProps {
  onClick: (args: any) => void;
  to?: string;
  isLoading?: boolean;
}

export const MoreButton = ({ to, onClick, isLoading }: IProps) => (
    <Segment container>
      <div className={s.moreButton}>
        <FormattedMessage id="see-more">
          {(ariaLabel) =>
            <Button
              to={to}
              onClick={onClick}
              // arrow={!isLoading}
              arrowDirection="down"
              borderColor="dark"
              aria-label={ariaLabel as string}
            >
              {isLoading ? <><FormattedMessage id="loading" />...</> : <FormattedMessage id="see-more" />}
            </Button>
          }
        </FormattedMessage>
      </div>
  </Segment>
);
