import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import { navigate } from '@reach/router';

import createLink from 'utils/createLink';
import { MetaContainer } from 'containers/meta/MetaContainer';
import { Slices } from 'containers/slices/Slices';
import { PageSearchContainer } from 'containers/page-search/PageSearch';

import ArticleGridMasonry from 'components/article-grid/ArticleGridMasonry';
import { ArticleCard } from 'components/article-card/ArticleCard';
import { HomeHero } from 'components/home-hero/HomeHero';
import { HomeHeroItem } from 'components/home-hero/HomeHeroItem';
import { Image } from 'components/image/Image';
import { MoreButton } from 'components/more-button/MoreButton';
import { PrismicImage } from 'components/prismic-image/PrismicImage';
import RichText from 'components/rich-text/RichText';
import { Segment } from 'components/layout/Segment';
import { Flowbox } from 'components/flowbox/Flowbox';
import { Container } from 'components/layout/Container';

import { fragmentMeta, fragmentLink } from 'fragments';

interface IProps {
  data: any;
  pageContext: {
    lang: string;
  };
}

const VISIBLE_ARTICLES_COUNT = 6;

const Home = (props: IProps) => {
  const { data } = props;
  const pageData = get(data, 'prismic.allHomes.edges.0.node', {});
  const carousel = get(pageData, 'carousel', []);
  const allArticles = get(pageData, 'article_list', []);
  const sliceData = get(pageData, 'body', []) || []; // body can be null

  const [visibleArticles, setVisibleArticles] = useState(allArticles ? allArticles.slice(0, VISIBLE_ARTICLES_COUNT) : []);
  const [hasShowMore, setHasShowMore] = useState(allArticles ? allArticles.length > VISIBLE_ARTICLES_COUNT : false);

  // Render carousel item
  const renderCarousel = (item: any, i: any) => {
    if (isEmpty(item) || isEmpty(item.carousel_image)) { return; }
    const imageUrl = !isEmpty(item.carousel_image.x1) ? get(item, 'carousel_image.x1.url', '') : '';

    return (
      <HomeHeroItem
        key={i}
        link={createLink(item.carousel_link)}
        kicker={RichText.asText(item.carousel_kicker)}
        heading={RichText.asText(item.carousel_heading)}
        image={<Image src={imageUrl} alt={get(item, 'carousel_image.alt', '') || ''} />}
        dark={get(item, 'carousel_text_color', '') === 'dark'}
      />
    );
  };

  // Render article list item
  const renderItem = ({ article_list_link: item }: any) => {
    if (isEmpty(item) || isEmpty(item.category)) { return; }

    const image = !isEmpty(item.image) && !isEmpty(item.image.x1) ?
      <PrismicImage image={item.image} view="x1" /> : undefined;

    return (
      <ArticleCard
        key={item._meta.uid}
        link={createLink(item)}
        heading={RichText.asText(item.title)}
        description={RichText.asText(item.list_description)}
        category={RichText.asText(item.category.title)}
        image={image}
        align={get(item, 'text_alignment', 'left').toLowerCase()}
      />
    );
  };

  const showMore = () => {
    setVisibleArticles(allArticles);
    setHasShowMore(false);
  };

  const onSearch = (category?: string, type?: string, region?: string) => {
    const queryParams = { category, type, region };
    navigate(`/search?${queryString.stringify(queryParams)}`);
  };

  return (
    <>
      <MetaContainer data={pageData} />

      {!isEmpty(carousel) &&
        <HomeHero>
          {carousel.map(renderCarousel)}
        </HomeHero>
      }

      <Segment label="Article list">
        <ArticleGridMasonry
          heading={RichText.asText(pageData.article_list_title)}
          description={RichText.asText(pageData.article_list_description)}
        >
        {!isEmpty(visibleArticles) && visibleArticles.map(renderItem)}
        </ArticleGridMasonry>
      </Segment>

      {hasShowMore &&
        <MoreButton onClick={showMore} />
      }

      <Container>
        <Flowbox flowboxKey="1YlVZlKzQtygYI8tbKh1zw" />
      </Container>

      <Segment label="Search section">
        <PageSearchContainer onSearch={onSearch} />
      </Segment>

      {!isEmpty(sliceData) && <Slices data={sliceData} />}

    </>
  );
};

export const query = graphql`
  query home($lang: String!){
    prismic {
      allHomes(lang: $lang) {
        edges {
          node {
            _meta {
              ...meta
            }
            title
            description
            image
            seo_title
            seo_description
            seo_image
            carousel {
              carousel_image
              carousel_kicker
              carousel_heading
              carousel_text_color
              carousel_link {
                _linkType
                __typename
                ...link
              }
            }
            article_list_title
            article_list_description
            article_list {
              article_list_link {
                _linkType
                __typename
                ... on  PRISMIC_Article {
                  _meta {
                    ...meta
                  }
                  title
                  image
                  list_description
                  text_alignment
                  category {
                    _linkType
                    ... on PRISMIC_Article_category {
                      _meta {
                        ...meta
                      }
                      title
                    }
                  }
                }
                ... on PRISMIC_Page_content{
                  _meta {
                    uid
                    id
                  }
                  title
                  image
                  list_description
                  text_alignment
                  category {
                    _linkType
                    ... on PRISMIC_Article_category {
                      _meta {
                        ...meta
                      }
                      title
                    }
                  }
                }
                ... on PRISMIC_Flights_to_iceland {
                  _meta {
                    ...meta
                  }
                  title
                  image
                  list_description
                  text_alignment
                  category {
                    _linkType
                    ... on PRISMIC_Article_category {
                      _meta {
                        ...meta
                      }
                      title
                    }
                  }
                }
                ... on PRISMIC_Festivals {
                  _meta {
                    ...meta
                  }
                  title
                  image
                  list_description
                  text_alignment
                  category {
                    _linkType
                    ... on PRISMIC_Article_category {
                      _meta {
                        ...meta
                      }
                      title
                    }
                  }
                }
                ... on PRISMIC_Regions_of_iceland {
                  _meta {
                    ...meta
                  }
                  title
                  image
                  list_description
                  text_alignment
                  category {
                    _linkType
                    ... on PRISMIC_Article_category {
                      _meta {
                        ...meta
                      }
                      title
                    }
                  }
                }
              }
            }
            body {
              ... on PRISMIC_HomeBodyPartners {
                type
                primary {
                  partner_heading
                  partner_alignment
                }
                fields {
                  partner {
                    ... on PRISMIC_Partner {
                      name
                      image
                      link {
                        _linkType
                        ... on PRISMIC__ExternalLink {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

Home.fragments = [fragmentMeta, fragmentLink];

export default Home;
