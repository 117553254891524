import React from 'react';

import s from './PageSearch.scss';

interface IProps {
  children: React.ReactNode;
}

export const PageSearch = ({ children }: IProps) => {

  return (
    <div className={s.pageSearch}>
      <div className={s.pageSearch__search}>
        {children}
      </div>
    </div>
  );
};
