import React, { cloneElement, useState, useRef, useEffect, Children } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { isEmpty } from 'lodash';

import { useSwipe } from 'hooks/use-swipe';
import { useKeyDown } from 'hooks/use-keydown';
import { UIContext } from 'context/ui';

import { CarouselButton } from 'components/carousel-button/CarouselButton';
import { ScrollIndicator } from 'components/scroll-indicator/ScrollIndicator';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import { HeroIndicators } from './HeroIndicators';
import HeroProgress from './HeroProgress';

import s from './HomeHero.scss';

interface IProps {
  children: React.ReactNode;
}

export const HomeHero = ({ children }: IProps) => {

  const [activeSlide, onUpdate] = useState(0);
  const [direction, onUpdateDirection] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const carouselNode = useRef<HTMLDivElement>(null);
  const swipeDirection = useSwipe(carouselNode);
  const { isDesktop, setHeaderTheme, canTransition, setHeaderScrolled, menuOpen } = React.useContext<any>(UIContext);

  const slides = Children.toArray(children);
  const slideCount = slides.length;
  const slide = slides[activeSlide] as React.ReactElement<any>;

  const next = activeSlide + 1 <= (slideCount - 1) ? activeSlide + 1 : 0;
  const prev = activeSlide - 1 >= 0 ? activeSlide - 1 : slideCount - 1;

  const nextSlide = slides[next] as React.ReactElement<any>;

  const setHeaderConditional = () => {
    setHeaderTheme(isScrolled || slide.props.dark ? 'dark' : isDesktop ? 'logoLight' : 'light');
  };

  if (isEmpty(slides)) { return null; }

  const onNext = () => {
    onUpdate(next); onUpdateDirection(1);
  };

  const onPrev = () => {
    onUpdate(prev); onUpdateDirection(0);
  };

  useEffect(() => {
    if (!swipeDirection) { return; }

    if (swipeDirection === 'left') {
      onNext();
    } else if (swipeDirection === 'right') {
      onPrev();
    }}, [swipeDirection]);

  useEffect(() => {
    setHeaderConditional();
  }, [isDesktop]);

  useEffect(() => {
    setHeaderConditional();
    setHeaderScrolled(isScrolled ? true : false);
  }, [isScrolled]);

  const keys = useKeyDown();

  useEffect(() => {
    if (!carouselNode.current) {
      return;
    }

    if (carouselNode.current === document.activeElement) {
      if (keys.includes(39)) { onNext(); }
      if (keys.includes(37)) { onPrev(); }
      // on space pause carousel?
      if (keys.includes(32)) { setIsPaused(!isPaused); }
    }
  }, [keys]);

  return (
    <>
    <section
      tabIndex={menuOpen ? -1 : 0}
      className={s('homeHero', { transition: !canTransition })}
      ref={carouselNode}
      aria-label="Homepage carousel"
    >
      <ViewportEnter once={false} threshold={0} onEnter={() => { setIsScrolled(false); }} onExit={() => { setIsScrolled(true); }}>
        <div className={s.homeHero__waypoint} />
      </ViewportEnter>
      <div className={s.homeHero__inner}>
        <div className={s.homeHero__children}>
          {children}
        </div>
        <TransitionGroup>
          {cloneElement(slide, {
            direction,
            active: true,
            nextSlide,
            progress: (
              <HeroProgress
                activeSlide={activeSlide}
                next={next}
                onUpdate={onUpdate}
                paused={menuOpen || isPaused}
              />
            ),
          })}
        </TransitionGroup>
      </div>

      <div className={s.homeHero__buttonWrap}>
        <CarouselButton
          direction="previous"
          onClick={() => { onPrev(); }}
        />
        <CarouselButton
          direction="next"
          onClick={() => { onNext(); }}
        />
      </div>
      { !isDesktop && (
        <HeroIndicators
          count={slideCount}
          index={activeSlide}
          onUpdate={onUpdate}
          onUpdateDirection={onUpdateDirection}
        />
      )}
    </section>

    <div className={s.homeHero__scrollIndicator}>
      <ScrollIndicator />
    </div>
    </>
  );
};
