import React, { useEffect } from 'react';

declare const window: FlowboxWindow;

interface FlowboxFunction extends Function {
  q?: any;
}

interface FlowboxWindow extends Window {
  flowbox: FlowboxFunction;
}

interface Props {
  flowboxKey: string;
  tags?: Array<string>;
}

export const Flowbox: React.FC<Props> = ({ flowboxKey, tags }) => {
  const scriptId = 'flowbox-js-embed';
  const containerId = 'js-flowbox-flow_' + flowboxKey;

  useEffect(() => {
    // https://help.getflowbox.com/en/articles/2550100-embedding-a-flowbox-flow-on-your-website
    if (!window.flowbox) {
      let f: FlowboxFunction = function () {
        f.q.push(arguments);
      };
      f.q = [];
      window.flowbox = f;
    }

    if (!document.getElementById(scriptId)) {
      const s = document.createElement('script');
      const fjs = document.scripts[document.scripts.length - 1];
      s.id = scriptId;
      s.async = true;
      s.src = 'https://connect.getflowbox.com/flowbox.js';
      fjs.parentNode && fjs.parentNode.insertBefore(s, fjs);
    }

    window.flowbox('init', {
      container: `#${containerId}`,
      key: flowboxKey,
      locale: 'en-GB',
      tags: tags && tags.length ? tags : [],
    });
  }, []);

  return <div id={containerId} />;
};
