import React from 'react';

import { Container } from 'components/layout/Container';

import s from './HeroIndicators.scss';

interface IProps {
  count: number;
  index: number;
  onUpdate: (args: number) => void;
  onUpdateDirection: (args: number) => void;
}

export const HeroIndicators = ({ count, index, onUpdate, onUpdateDirection }: IProps) => {

  const onClick = (i: number) => {
    onUpdate(i);
    onUpdateDirection(index < i ? 1 : 0);
  };

  const buttons: any = [];

  for (let i = 0; i < count; i++) {
    buttons.push(
      <button
        title={`Show item ${i + 1}`}
        key={`${s.heroIndicators__button}--${i}`}
        className={s('heroIndicators__button', { active: index === i })}
        onClick={() => { onClick(i); }}
      />);
  }

  return (
    <div className={s('heroIndicators')}>
      <Container>
        <div className={s.heroIndicators__inner}>
          {buttons}
        </div>
      </Container>
    </div>
  );
};
