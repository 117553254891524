const fontColor = '#ffffff';
const darkColor = '#161616';

export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#d4d4d4' : fontColor,
    padding: '6px 30px 6px 30px',
    backgroundColor: darkColor,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      color: '#d4d4d4',
    },
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    position: 'relative',
    display: 'flex',
    width: '100%',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: fontColor,
    borderBottom: `2px solid ${fontColor}`,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#767676' : darkColor,
  }),
  indicatorSeparator: () => ({
    border: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    border: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: '6px 30px 6px 30px',
    textAlign: 'left',
  }),
  input: (provided) => ({
    ...provided,
    padding: '0',
    margin: '0',
  }),
  singleValue: (provided, state) => {
    const transition = 'opacity 300ms';
    const color = darkColor;
    return { ...provided, color, transition };
  },
  container: () => ({
    display: 'inline-flex',
    width: '100%',
  }),
  menu: () => ({
    position: 'absolute',
    top: '35px',
    padding: '15px 0px',
    width: '100%',
    backgroundColor: darkColor,
    zIndex: 11,
    textAlign: 'left',
  }),
};
