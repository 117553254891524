import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import { UIContext } from 'context/ui';

import Chevron from 'assets/svg/icons/chevron.svg';

import { selectStyles } from './SelectStyles';

import s from './Filter.scss';

interface IFilter {
  label: string;
  value: string;
}

interface IProps {
  heading?: string;
  children?: IFilter[];
  active?: boolean;
  placeholder?: string;
  defaultValue?: string;
  activeValue?: string;
  id: string;
  onClick?: (args: any) => void;
  disabled?: boolean;
}

export const Filter = ({
    children,
    heading,
    placeholder,
    id,
    active,
    disabled,
    defaultValue,
    activeValue,
    onClick = () => null,
  }: IProps) => {

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {<Chevron className={s.filter__chevron} />}
      </components.DropdownIndicator>
    );
  };

  const [currentValue, setCurrentValue] = useState(null);

  // get index if we have default values, perhaps not needed at all?
  const defaultIndex = children && defaultValue ? children.map((e) => e.value ).indexOf(defaultValue) : -1;

  const getSelectValue = (value: string) => {
    return children ? children.map((item) => item.value ).indexOf(value) : [];
  };

  useEffect(() => {
    // '-1' is non-select value of react-select
    if (activeValue !== -1 && children) {
      setCurrentValue(children[getSelectValue(activeValue)]);
    }
  }, [activeValue, children]);

  return (
    <label className={s.filter}>
      <h2 className={s.filter__heading}>{heading}</h2>
      <div className={s.filter__options}>
        <Select
          options={children}
          onChange={onClick}
          styles={selectStyles}
          placeholder={placeholder}
          components={{ DropdownIndicator }}
          defaultValue={(defaultIndex !== -1) ? children[defaultIndex] : null}
          isDisabled={disabled}
          isSearchable={false}
          hideSelectedOptions={true}
          value={currentValue ? currentValue : null}
        />
      </div>
    </label>
  );
};
