import React, { Children } from 'react';

import { Container } from 'components/layout/Container';
import { CtaButton } from 'components/cta-button/CtaButton';

import s from './SearchBar.scss';

interface IProps {
  children: React.ReactNode;
  disabled?: boolean;
  onSearch: React.FormEventHandler<HTMLFormElement>;
}

export const SearchBar = ({ children, disabled = false, onSearch }: IProps) => {
  if (!children) { return null; }

  return (
    <form className={s.searchBar} onSubmit={onSearch}>
      <Container>
        <div className={s.searchBar__grid}>
          {Children.map(children, (child, i) =>
            <div className={s.searchBar__item} key={`${s.searchBar}-${i}`}>
              {child}
            </div>,
          )}
          <CtaButton
            className={s.searchBar__button}
            arrow={false}
            disabled={disabled}
          >Search
          </CtaButton>

        </div>
      </Container>
    </form>
  );
};
