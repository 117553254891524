import React, { PureComponent } from 'react';

import gsap from 'gsap';

import s from './HeroProgress.scss';

interface IProps {
  onUpdate: (args: number) => void;
  next: number;
  activeSlide: number;
  paused: boolean;
}

export default class Progress extends PureComponent<IProps> {
  t = gsap.timeline({
    onComplete: () => {this.props.onUpdate(this.props.next); },
  });

  componentDidMount() {
    this.animate();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.paused !== this.props.paused) {
      if (this.props.paused) {
        this.t.pause();
      } else {
        this.t.play();
      }
    }
  }

  componentWillUnmount() {
    this.t.kill();
  }

  animate = () => {
    this.t.fromTo(
      `.${s.progress__line}`,
      { scaleX: 0 },
      {
        scaleX: 1,
        transformOrigin: 'top left',
        ease: 'none',
        duration: 5,
      },
    )
    .fromTo(
      `.${s.progress__line}`,
      { scaleX: 1 },
      {
        scaleX: 0,
        transformOrigin: 'top right',
        immediateRender: false,
        ease: 'power4.in',
        duration: 0.5,
      },
    );
  }

  render() {
    return (
      <div className={s('progress')}>
        <div className={s.progress__line} />
      </div>
    );
  }
}
