import React, { cloneElement, useRef, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';

import { UIContext } from 'context/ui';

import { Button } from 'components/button/Button';
import { Row } from 'components/layout/Row';

import s from './HomeHeroItem.scss';

interface IProps {
  direction?: 1 | 0;
  active?: boolean;
  kicker?: string | React.ReactElement<any>;
  heading: string;
  image: React.ReactElement<any>;
  nextImage?: React.ReactElement<any>;
  nextSlide?: React.ReactElement<any>;
  link: string;
  linkText?: React.ReactElement<any>;
  progress?: React.ReactElement<any>;
  dark?: boolean;
}

export const HomeHeroItem = ({
  direction,
  active, // abs position active guys
  kicker,
  heading,
  image,
  nextImage,
  nextSlide,
  link,
  linkText = <FormattedMessage id="go-explore" />,
  progress,
  dark,
  ...props
 }: IProps) => {

  const item = useRef<HTMLDivElement>(null);
  const contentNode = useRef<HTMLDivElement>(null);
  const nextImageNode = useRef<HTMLDivElement>(null);

  const { setHeaderTheme, headerScrolled, isDesktop, menuOpen } = useContext<any>(UIContext);

  const ease = 'power2.inOut';
  const duration = 300;
  const path = direction === 1 ? {
    start: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
    end: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
  } : {
    start: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
    end: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
  };

  const t = gsap.timeline();
  t.addLabel('start', 0);

  const onEnter = () => {
    t.fromTo(`.${s.active}:first-child .${s.homeHeroItem__imageClip}`,
      { webkitClipPath: path.start, clipPath: path.start },
      {
        webkitClipPath: path.end,
        clipPath: path.end,
        ease,
        duration: (duration / 1000),
      }, 'start');

    t.to(contentNode.current!,
      { x: 0, autoAlpha: 1, ease, duration: (duration / 1000) }, 'start');
  };

  const onEntering = () => {
    gsap.set(item.current, { zIndex: 2 });
    gsap.set(nextImageNode.current, { zIndex: 4 });
    gsap.set(contentNode.current, { x: direction === 1 ? 70 : -50, autoAlpha: 0 });
  };

  const onEntered = () => {
    gsap.set(item.current, { zIndex: 2 });
  };

  const onExiting = () => {
    gsap.set(item.current, { position: 'absolute', zIndex: 1 });
    gsap.set(nextImageNode.current, { zIndex: 3 });

    t.fromTo(contentNode.current!,
    { autoAlpha: 1 },
    { autoAlpha: 0, ease, duration: (duration / 1000) }, 'start');
  };

  useEffect(() => {
      setHeaderTheme(dark || headerScrolled ? 'dark' : isDesktop ? 'logoLight' : 'light');
  }, [dark]);

  return (
    <Transition
      {...props}
      timeout={duration}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExiting={onExiting}
      // unmountOnExit={false}
    >
        <div className={s('homeHeroItem', { active, dark })} aria-hidden={!active}>
          <div className={s.homeHeroItem__inner} ref={item}>

            <div className={s.homeHeroItem__imageWrap}>
              <div className={s.homeHeroItem__imageClip}>
                {cloneElement(image, {
                  className: s.homeHeroItem__image,
                })}
              </div>
            </div>

            <div className={s.homeHeroItem__contentContainer}>
              <Row>
                <div className={s.homeHeroItem__content}>
                  <div className={s.homeHeroItem__contentInner} ref={contentNode}>
                    {kicker && (<div className={s.homeHeroItem__kicker}>{kicker}</div>)}
                    <div className={s.homeHeroItem__headingWrap}>
                      <h1 className={s.homeHeroItem__heading}>{heading}</h1>
                      <div className={s.homeHeroItem__button}>
                        <FormattedMessage id="go-explore">
                          {
                            (defaultLinkText) =>
                            <Button
                              filled={isDesktop ? false : true}
                              to={link}
                              aria-label={`${linkText} - ${heading}`}
                              dark={dark}
                              tabIndex={active && !menuOpen ? 0 : -1}
                            >
                              {linkText || defaultLinkText}
                            </Button>
                          }
                        </FormattedMessage>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>

        <div className={s.homeHeroItem__nextImageWrap} ref={nextImageNode}>
          <div className={s.homeHeroItem__nextImageDimensions}>
            <div className={s.homeHeroItem__nextImageInner}>
              <div className={s.homeHeroItem__imageClip}>
                {nextSlide && cloneElement(nextSlide.props.image, {
                  className: s.homeHeroItem__image,
                })}
              </div>
              <div className={s.homeHeroItem__progress}>{progress}</div>
            </div>
          </div>

        </div>

      </div>
    </Transition>
  );
};
