import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import setQueryString from 'set-query-string';

import { PageSearch } from 'components/page-search/PageSearch';
import { SearchBar } from 'components/search-bar/SearchBar';
import { Filter } from 'components/search-bar/Filter';

interface IProps {
  onSearch: (category?: string, type?: string, region?: string) => void;
  onLoad?: (category?: string, type?: string, region?: string) => void;
  onValuesChanged?: (category?: string, type?: string, region?: string) => void;
}

interface IFilter {
  label: string;
  id: string;
}

function mapJson(data: any, key: string) {
  if (!data || !data[key]) {
    return null;
  }

  return data[key]
    .map((r: any) => ({ label: r.name, value: r.id.toString() }))
    .sort((a: IFilter, b: IFilter) => a.label.localeCompare(b.label));
}

export const PageSearchContainer = ({ onSearch, onLoad, onValuesChanged }: IProps) => {
  const [typeFilterDisabled, setTypeFilterDisabled] = useState(true);
  const [category, setSelectedCategory] = useState('');
  const [type, setSelectedType] = useState('');
  const [region, setSelectedRegion] = useState('');
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});

  const setVariablesFromQuerystring = (params: string) => {
    const queryParams = queryString.parse(params);
    const { category: categoryQs, type: typeQs, region: regionQs } = queryParams;
    const result: any = {};

    if (categoryQs) {
      const cat = Array.isArray(categoryQs) ? categoryQs[0] : categoryQs;
      result.category = cat;
      setSelectedCategory(cat);
    }

    if (typeQs) {
      const t = Array.isArray(typeQs) ? typeQs[0] : typeQs;
      result.type = t;
      setSelectedType(t);
    }

    if (regionQs) {
      const r = Array.isArray(regionQs) ? regionQs[0] : regionQs;
      result.region = r;
      setSelectedRegion(r);
    }

    return result;
  };

  useEffect(() => {
    const onPopState = () => {
      const vars = setVariablesFromQuerystring(window.location.search);
      onSearch(vars.category, vars.type, vars.region);
    };

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  useEffect(() => {
    const vars = setVariablesFromQuerystring(window.location.search);
    if (onLoad) {
      onLoad(vars.category, vars.type, vars.region);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError(null);

      const searchParams = new URLSearchParams();

      if (category) {
        searchParams.set('category', category);
      }

      const url = new URL('/.netlify/functions/api-categories', window.location.origin);
      url.search = searchParams.toString();

      try {
        const result = await fetch(url.href);

        if (!result.ok) {
          // tslint:disable-next-line
          console.error('Fetching not OK', result.status, result.statusText);
          setError('Error');
        } else {
          const jsonData = await result.json();

          setData(jsonData);

          if (onValuesChanged) {
            // the logic for the value bound to the id is inside react-select... 🙈
            const catValue = (mapJson(jsonData, 'categories') || []).find((i: any) => i.value === category);
            const subcatValue = (mapJson(jsonData, 'subCategories') || []).find((i: any) => i.value === type);

            const v = subcatValue ? subcatValue.label : (catValue ? catValue.label : null);
            onValuesChanged(v);
          }
        }
      } catch (e) {
        // tslint:disable-next-line
        console.error('error fetching', e);
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [category]);

  const updateValues = () => {
    if (onValuesChanged) {
      // the logic for the value bound to the id is inside react-select... 🙈
      const catValue = (mapJson(data, 'categories') || []).find((i) => i.value === category);
      const subcatValue = (mapJson(data, 'subCategories') || []).find((i) => i.value === type);

      const v = subcatValue ? subcatValue.label : (catValue ? catValue.label : null);
      onValuesChanged(v);
    }
  };

  useEffect(() => {
    updateValues();
  }, [data]);

  const onFilterTypeUpdate = (key: string) => (e: any) => {
    const { value } = e;

    if (key === 'category') {
      setSelectedCategory(value);
      // we need to clear selected type on category change
      setSelectedType('');
    }

    if (key === 'region') {
      setSelectedRegion(value);
    }

    if (key === 'type') {
      setSelectedType(value);
    }
  };

  const localOnSearch = (e: React.FormEventHandler<HTMLFormElement>) => {
    e.preventDefault();

    setQueryString({ category, type, region }, { clear: true });
    onSearch(category, type, region);
    updateValues();
  };

  useEffect(() => {
    if (category !== '') {
      setTypeFilterDisabled(false); // enabled types
    }

  }, [category]);

  return (
    <PageSearch>
      <SearchBar disabled={loading} onSearch={localOnSearch}>
        <Filter
          heading="Category"
          placeholder="Select Category"
          id="category"
          onClick={onFilterTypeUpdate('category')}
          activeValue={category}
          disabled={loading}
        >
          {!loading && mapJson(data, 'categories')}
        </Filter>

        <Filter
          heading="Type"
          id="type"
          placeholder="Select Type"
          onClick={onFilterTypeUpdate('type')}
          disabled={loading || typeFilterDisabled}
          activeValue={type}
        >
          {!loading && mapJson(data, 'subCategories')}
        </Filter>

        <Filter
          heading="Region"
          id="region"
          placeholder="All Regions"
          onClick={onFilterTypeUpdate('region')}
          defaultValue="all"
          activeValue={region}
          disabled={loading}
        >
          {!loading && mapJson(data, 'regions')}
        </Filter>
      </SearchBar>
    </PageSearch>
  );
};
